<template>
  <div class="content">
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>
    <div class="content-mian">
      <div class="process-content"> 
        <div class="bold-title size-20 content-title">一站式钣金加工方案解决厂商</div>
        <div class="size-12 content-sub-title"><span class="sub-title-text">钣金加工找易宝通/省时省钱好品质/助力客户组装好产品</span></div>
        <div class="process-content-info">
          <div v-title="item.desc" class="content-info-item" v-for="(item,key) in processList" :key="key">
            <img :src="item.img" :alt="item.title" class="flow-img" />
            <div class="flow-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-mian mian-gray">
      <div class="bold-title size-20 content-title">为什么选择我们</div>
      <div class="size-12 content-sub-title"><span class="sub-title-text">易宝通6大优势，给您选择我们的理由</span></div>
      <ul class="choose-ul">
        <li>
          <span class="li-num size-40 bold-title">66000</span>
          <span class="li-desc size-16">㎡</span>
          <div class="li-title size-16">总占地面积</div>
        </li>
        <li>
          <span class="li-num size-40 bold-title">200</span>
          <span class="li-desc size-16">+台</span>
          <div class="li-title size-16">现代化设备</div>
        </li>
        <li>
          <span class="li-num size-40 bold-title">2</span>
          <span class="li-desc size-16">条</span>
          <div class="li-title size-16">柔性生产线</div>
        </li>
        <li>
          <span class="li-num size-40 bold-title">10</span>
          <span class="li-desc size-16">年以上</span>
          <div class="li-title size-16">工人平均工作经验</div>
        </li>
        <li>
          <span class="li-num size-40 bold-title">12w</span>
          <span class="li-desc size-16">台</span>
          <div class="li-title size-16">智能箱生产能力</div>
        </li>
      </ul>
      <div class="equipment-content" v-show="!isShow">
        <div class="bold-title size-20 content-title">成熟技术设备</div>
        <div class="text-doru"></div>
        <Slider :slides="equList" v-slot="{ currentSlide }">
            <div class="product-slider" v-for="(item,key) in currentSlide" :key="key">
                <img  :alt="item.title" :title="item.title" :src="item.img" class="product-slider-img">
                <div class="product-slider-title">{{ item.title }}</div>
            </div>
        </Slider>
      </div>

       <div class="equipment-content" v-show="isShow">
        <div class="bold-title size-20 content-title">成熟技术设备</div>
        <div class="text-doru"></div>
        <Slider :slides="equipmentList" v-slot="{ currentSlide }">
            <div class="product-slider" v-for="(item,key) in currentSlide" :key="key">
                <img  :alt="item.title" :title="item.title" :src="item.img" class="product-slider-img">
                <div class="product-slider-title">{{ item.title }}</div>
            </div>
        </Slider>
      </div>
    </div>

    <div class="bold-title size-20 content-title">钣金工艺细节</div>
    <div class="size-12 content-sub-title"><span class="sub-title-text">4.0柔性加工生产线，打造创新技术高质量品质</span></div>
    <div class="info">
      <div class="info-left">
        <img src="@/assets/process1.png" alt="4.0柔性生产线" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">01</span>
            <span class="flow-title">4.0柔性生产线设备工艺优势：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='速度快' />
              <div class="icon-title bold-title size-12">量产速度快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/precision.png" alt='精密度' />
              <div class="icon-title bold-title size-12">精密度高</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品质好' />
              <div class="icon-title bold-title size-12">加工品质好</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/adaptability.png" alt='适应性' />
              <div class="icon-title bold-title size-12">适应性广</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
            4.0柔性生产线使用先进的数字化技术，将生产线打造成高度灵活的制造系统，通过将
            传感器、机器人、自动化系统、数据分析等技术融入生产线中，实现生产流程的自动化
            和智能化，在钣金加工行业中，降低生产成本，提高生产效率，大幅度提高钣金制品的
            质量和准确性，同时提高生产线的灵活性和适应性，并能快速响应市场需求变化满足客
            户加工需求。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>

    <div class="info info-gray">
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">02</span>
            <span class="flow-title">大功率激光切割精准下料：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/big-power.png" alt='功率' />
              <div class="icon-title bold-title size-12">大功率</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/accuracy.png" alt='精度' />
              <div class="icon-title bold-title size-12">公差(0.05)精度</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/automation.png" alt='自动化' />
              <div class="icon-title bold-title size-12">全自动化</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/material.png" alt='材料' />
              <div class="icon-title bold-title size-12">节约材料</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
           3000w激光器切割，速度快、精密高，公差正负0.05mm，可以用于大型钣金板材
          或薄板料件按照设计要求进行高效率、自动化高精确切割，减少废品和二次加工，
          节约材料为企业节省成本。
          </div>
        </div>
      </div>
       <div class="info-left">
        <img src="@/assets/process2.png" alt="大功率激光切割" />
      </div>
      <div style="clear:both;"></div>
    </div>

    <div class="info">
      <div class="info-left">
        <img src="@/assets/process3.png" alt="落冲成型机器" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">03</span>
            <span class="flow-title">进口品牌落冲成型机器：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/im-equipment.png" alt='设备' />
              <div class="icon-title bold-title size-12">进口设备</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='质量' />
              <div class="icon-title bold-title size-12">质量稳定</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/automation.png" alt='自动化' />
              <div class="icon-title bold-title size-12">全自动化</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/adaptability.png" alt='适应性' />
              <div class="icon-title bold-title size-12">适应性广
            </div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
            进口一线品牌落冲成型设备具有更高的自动化程度、生产效率、适应性和智能化程度可
            生产形状更为复杂的零件，并且生产出的零件质量更加稳定，主要用于生产各种形状的
            薄壁结构件、无缝箱体等。相比传统的切割和折弯工艺，落冲成型可以将多个工序合并
            为一步，从而提高生产效率，降低生产成本。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>

    <div class="info info-gray">
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">04</span>
            <span class="flow-title">高规格数控冲床折弯一体机：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/big-power.png" alt='系统' />
              <div class="icon-title bold-title size-12">智能化系统</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/system.png" alt='精密度' />
              <div class="icon-title bold-title size-12">精密度高</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/shape.png" alt='形状' />
              <div class="icon-title bold-title size-12">可各种形状</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品质' />
              <div class="icon-title bold-title size-12">品质稳定</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
           数控冲床折弯一体机是一种集冲剪、折弯、成型等多项工艺于一体的钣金加工设备，它
          通过电脑控制加工程序，将钣金原材料进行冲孔、裁剪、折弯等工艺加工，从而实现快
          速、精确的批量生产，可以加工各种金属板材制品，例如电子、通讯、计算机、家电、
          机械、工程机械、广告制品、汽车、家具、医疗设备等领域的零部件、外壳、箱体、支
          架等，相比传统机器，数控冲床折弯一体机可以大幅度提高加工精度和效率，降低工人
          劳动强度，还可以节省大量的时间和成本，具有非常广泛的应用前景。
          </div>
        </div>
      </div>
       <div class="info-left">
        <img src="@/assets/process4.png" alt="高规格数控冲床" />
      </div>
      <div style="clear:both;"></div>
    </div>
    
     <div class="info">
      <div class="info-left">
        <img src="@/assets/process5.png" alt="气动压铆机" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">05</span>
            <span class="flow-title">高品质气动压铆机设备：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品质' />
              <div class="icon-title bold-title size-12">品质稳定</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='生产效率' />
              <div class="icon-title bold-title size-12">生产效率快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/experience.png" alt='经验' />
              <div class="icon-title bold-title size-12">丰富经验</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/species.png" alt='种类' />
              <div class="icon-title bold-title size-12">加工种类多
            </div>
            <div style="clear:both;"></div>
            </div>
          </div>

          <div class="info-desc">
           我们的团队成员都经过专业的培训和多年的实践，掌握了熟练的落冲和压铆技术，能够
            快速、高效地完成大批量的钣金加工任务，严格把控每一道工序中，确保每个零件的质
            量都符合要求。可以用于加工各种钣金产品，例如汽车、电子设备、家电、机械设备等
            领域的产品。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>


    <div class="bold-title size-20 content-title info-gray">电泳加工细节</div>
    <div class="size-12 content-sub-title info-gray"><span class="sub-title-text">阴极电泳涂装技术在自动化程度、生产效率、涂装质量、涂料利用率等方面均具有优势，可以满足客户的个性化需求。</span></div>
    <div class="info info-gray">
      <div class="info-left">
        <img src="@/assets/process-2-1.png" alt="阴极电泳" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">01</span>
            <span class="flow-title">表面阴极电泳处理：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/abrasive.png" alt='耐磨性' />
              <div class="icon-title bold-title size-12">耐磨性强</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='生产效率' />
              <div class="icon-title bold-title size-12">生产效率快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/rprv.png" alt='防腐防锈' />
              <div class="icon-title bold-title size-12">防腐防锈</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/sm-color.png" alt='颜色' />
              <div class="icon-title bold-title size-12">颜色光滑</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
           我们的团队拥有丰富的经验和专业知识，涂装过程中使用的涂料富含固体颗粒，可以同
            时涂覆多个工件，并且可以制造出多种不同的涂层颜色，涂层的厚度、附着力和耐腐蚀
            性能都非常优异，可以为工件提供长久的保护，可用于冷板件、不锈钢件、铝件、金属
            电镀件，贵金属饰件，灯具，钟表，眼镜，火机饰品，锁具及高档家具五金件的表面防
            护装饰。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>


    <div class="bold-title size-20 content-title">喷涂工艺细节</div>
    <div class="size-12 content-sub-title"><span class="sub-title-text">严格的质量控制和工艺流程，保证每一批涂装产品都能够符合客户的要求。</span></div>
    <div class="info">
      <div class="info-left">
        <img src="@/assets/process-3-1.png" alt="表面静电喷涂" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">01</span>
            <span class="flow-title">表面静电喷涂工序：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品质' />
              <div class="icon-title bold-title size-12">品质稳定</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='生产效率' />
              <div class="icon-title bold-title size-12">生产效率快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/rprv.png" alt='耐用性' />
              <div class="icon-title bold-title size-12">耐用性强</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/full-color.png" alt='颜色' />
              <div class="icon-title bold-title size-12">颜色饱满</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
           公司拥有丰富的静电喷涂经验和专业知识，使用的静电喷涂设备先进，能够实现高
          精度、高效率的喷涂作业，保证了产品表面的质量和稳定性，针对不同的客户需求
          提供量身定制的解决方案。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>


    <div class="bold-title size-20 content-title info-gray">装配工艺细节</div>
    <div class="size-12 content-sub-title info-gray"><span class="sub-title-text">严格遵守ISO质量管理体系，保证每一个装配环节的品质达到客户的要求。</span></div>
    <div class="info info-gray">
      <div class="info-left">
        <img src="@/assets/process-4-1.png" alt="装配工艺" />
      </div>
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">01</span>
            <span class="flow-title">制定科学合理的装配工艺方案：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品质' />
              <div class="icon-title bold-title size-12">提高品质</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='生产效率' />
              <div class="icon-title bold-title size-12">生产效率快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/consistency.png" alt='一致性' />
              <div class="icon-title bold-title size-12">一致性强</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/re-cost.png" alt='成本' />
              <div class="icon-title bold-title size-12">降低成本</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
            易宝通装配团队制定科学合理的装配方案和采用先进的装配工艺设备和灵活的装配方案，
            可以减少装配误差，提高装配精度和一致性，满足不同产品和客户的要求，从而提高产
            品质量，可以大幅提高装配效率，缩短生产周期，提高生产效益。
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div>

    <div class="info">
      <div class="info-right">
        <div class="info-content">  
          <div class="info-title size-18 bold-title">
            <span class="flow-num size-28">02</span>
            <span class="flow-title">专业品质团队进行终检自检：</span>
          </div>
          
          <div class="info-icon-list">
            <div class="icon-info">
              <img src="@/assets/quality.png" alt='品检' />
              <div class="icon-title bold-title size-12">专业品检</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/efficiency.png" alt='生产效率' />
              <div class="icon-title bold-title size-12">生产效率快</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/im-equipment.png" alt='设备' />
              <div class="icon-title bold-title size-12">先进设备</div>
            </div>
            <div class="icon-info">
              <img src="@/assets/satisfaction.png" alt='客户' />
              <div class="icon-title bold-title size-12">客户满意</div>
            </div>
            <div style="clear:both;"></div>
          </div>

          <div class="info-desc">
            自检与终检团队成员拥有多年的行业经验，建立了一套完善的质量控制体系，和先进的设
            备技术手段，包括光学仪器、测试仪器等，对每一个细节都非常关注，能够对产品进行全
            面的检测和测试，保证每一件产品都符合客户要求和行业标准。
          </div>
        </div>
      </div>
       <div class="info-left">
        <img src="@/assets/process-4-2.png" alt="品质团队" />
      </div>
      <div style="clear:both;"></div>
    </div>


    <div class="content-mian mian-gray">
      <div class="process-content"> 
        <div class="bold-title size-20 content-title">工厂实拍展示</div>
        <div class="size-12 content-sub-title"><span class="sub-title-text">全面展示生产车间的实拍场景展示图，为客户提供更好的了解和选择的依据。</span></div>
        <div class="factory-contnet">
          <div v-for="(item,key) in factoryList" :key="key" class="factory-item">
            <img :src="item.img" :alt="item.title" class="factory-img" />
          </div>
        </div>
      </div>
    </div>


    <TheContact />
  </div>
</template>

<script>
export default {
  name: 'Process',
  data() {
    return {
      screenWidth:document.body.scrollWidth ,
      slides: [
        {
          title: '加工实力',
          img: require('@/assets/process.png'),
        },
      ],
      processList:[
        {
          title:'（1）领料',
          img:require('@/assets/flow1.png'),
          desc:'<img class="flow-img" alt="领料" src="'+ require('@/assets/flow-desc1.png') +'"><div class="bold-title size-12">领料:</div><div class="size-10">工程师根据订单，计算出所需原材料尺寸及种类，向仓库申请领料，用于后续生产。</div>',
        },
        {
          title:'（2）下料',
          img:require('@/assets/flow2.png'),
          desc:'<img class="flow-img" alt="下料" src="'+ require('@/assets/flow-desc2.png') +'"><div class="bold-title size-12">下料:</div><div class="size-10">我们有严格的下料质检标准，确保每一块钣金都符合客户要求的尺寸和精度要求。</div>',
        },
        {
          title:'（3）折弯',
          img:require('@/assets/flow3.png'),
          desc:'<img class="flow-img" alt="折弯" src="'+ require('@/assets/flow-desc3.png') +'"><div class="bold-title size-12">折弯:</div><div class="size-10">我们的折弯团队都是经验丰富的专业人员，具有多年的钣金加工经验，能够快速高效地完成各种弯折加工任务。</div>',
        },
        {
          title:'（4）钳工',
          img:require('@/assets/flow4.png'),
          desc:'<img class="flow-img" alt="钳工" src="'+ require('@/assets/flow-desc4.png') +'"><div class="bold-title size-12">钳工:</div><div class="size-10">钳工团队配备了各种高精度的加工设备，如数控折弯机、数控冲床、数控剪板机等，能够满足各种复杂钣金加工的需求。</div>',
        },
        {
          title:'（5）焊接',
          img:require('@/assets/flow5.png'),
          desc:'<img class="flow-img" alt="焊接" src="'+ require('@/assets/flow-desc5.png') +'"><div class="bold-title size-12">焊接:</div><div class="size-10">我们焊接团队拥有先进的设备和丰富的经验焊工，可以满足不同类型的钣金焊接需求，能够保证高质量的焊接工艺。</div>',
        },{
          title:'（6）打磨',
          img:require('@/assets/flow6.png'),
          desc:'<img class="flow-img" alt="打磨" src="'+ require('@/assets/flow-desc6.png') +'"><div class="bold-title size-12">打磨:</div><div class="size-10">我们的打磨团队拥有多年的钣金加工经验，熟悉各种材料的打磨特点，能够根据不同产品的需求，保证产品的质量和美观度。</div>',
        }
        ,{
          title:'（7）表面处理',
          img:require('@/assets/flow7.png'),
          desc:'<img class="flow-img" alt="表面处理" src="'+ require('@/assets/flow-desc7.png') +'"><div class="bold-title size-12">表面处理:</div><div class="size-10">我们的团队掌握了多种表面处理工艺，如电泳户需求和产品材质选择最的适表面处理方法。</div>',
        }
        ,{
          title:'（8）半成品检验',
          img:require('@/assets/flow8.png'),
          desc:'<img class="flow-img" alt="半成品检验" src="'+ require('@/assets/flow-desc8.png') +'"><div class="bold-title size-12">半成品检验:</div><div class="size-10">我们配备了先进的检测设备，经验丰富的检测人员，他们对各种材料和工艺有着深入的了解能够及时准确地发现半成品中的问题。</div>',
        },
        {
          title:'（9）印刷/喷涂',
          img:require('@/assets/flow7.png'),
          desc:'<img class="flow-img" alt="印刷/喷涂" src="'+ require('@/assets/flow-desc9.png') +'"><div class="bold-title size-12">印刷/喷涂:</div><div class="size-10">采用高品质涂料和现代化的喷涂设备，能够确保涂层的平滑、均匀和美观，同时提高产品的耐久性和防腐蚀性能避免出现质量问题。</div>',
        },
        {
          title:'（10）成套组装',
          img:require('@/assets/flow10.png'),
          desc:'<img class="flow-img" alt="成套组装" src="'+ require('@/assets/flow-desc10.png') +'"><div class="bold-title size-12">成套组装:</div><div class="size-10">我们的钣金加工组装团队具备丰富的经验、高效率、严格的管理和灵活性，能够为客户提供优质的组装服务，满足客户的各种需求。</div>',
        },{
          title:'（11）质检',
          img:require('@/assets/flow11.png'),
          desc:'<img class="flow-img" alt="质检" src="'+ require('@/assets/flow-desc11.png') +'"><div class="bold-title size-12">质检:</div><div class="size-10">质检团队配备了各种先进的检测设备，许多经验丰富的工程师，根据国际标准对产品进行严格检测，确保每个产品都符合标准要求。</div>',
        },{
          title:'（12）入库包装',
          img:require('@/assets/flow9.png'),
          desc:'<img class="flow-img" alt="入库包装" src="'+ require('@/assets/flow-desc12.png') +'"><div class="bold-title size-12">入库包装:</div><div class="size-10">包装团队对不同包装材料、包装方法、运输方式等方面的专业知识了如指掌，能够提供专业的意见和建议。</div>',
        }
        // {
        //   title:'（13）代发货',
        //   img:require('@/assets/flow12.png'),
        //   desc:'<img class="flow-img" alt="代发货" src="'+ require('@/assets/flow-desc13.png') +'"><div class="bold-title size-12">代发货:</div><div class="size-10">交付团队对于包装、标记、质检、装车等每个细节都会认真考虑，并严格按照流程要求进行操作，确保货物无损且到达目的地的时间准确。</div>',
        // }
      ],
      equipmentList:[
        [
          {
            title:'4.0柔性生产线',
            img:require('@/assets/strength.png')
          },
          {
            title:'钣金',
            img:require('@/assets/metal.png')
          }
        ],
        [
          {
            title:'焊接',
            img:require('@/assets/weld.png')
          },
          {
            title:'激光切割',
            img:require('@/assets/cutting.png')
          }
        ]
      ],
      equList:[
        [
          {
            title:'4.0柔性生产线',
            img:require('@/assets/strength.png')
          },
          {
            title:'钣金',
            img:require('@/assets/metal.png')
          },
          {
            title:'焊接',
            img:require('@/assets/weld.png')
          },
          {
            title:'激光切割',
            img:require('@/assets/cutting.png')
          }
        ]
      ],
      factoryList:[
        {
          title:'工厂实拍',
          img:require('@/assets/factory1.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory2.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory3.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory4.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory5.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory6.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory7.png')
        },
        {
          title:'工厂实拍',
          img:require('@/assets/factory8.png')
        }
      ],
      isShow:false
    }
  },
   mounted() { 
    this.checkLength(this.screenWidth)
    let _this = this
      window.addEventListener("resize", () => { 
      return (() => {
          _this.screenWidth = document.body.scrollWidth 
      })()
    })
  },
 
   watch: {
    screenWidth: function (n) {
      this.checkLength(n)
    }
  },
  methods: {
    checkLength(n) {
      // console.log(n)
      if(n <= 1007) {
        this.isShow= true
      }else {
        this.isShow= false
      }
    } 
  }
}
</script>

<style scoped>
  .content-mian{
    padding:0 10%;
  }

  .mian-gray {
    background-color: #f0efef;
    padding-bottom:60px;
  }

  .content-title {
    width: 100%;
    text-align: center;
    padding:20px 0;
  }

  .content-sub-title {
    padding:0 10%;
    width: 100%;
    text-align: center;
  }

  .sub-title-text {
    display: inline-block;
    text-align: left;
  }

  .process-content {
    margin:60px 0px;
  }

  .process-content-info {
    margin:20px 0px;
    text-align: left;
  }

  .content-info-item {
    position: relative;
    width: 14.6%;
    display: inline-block;
    padding-top: 13.5%;
    margin:10px 1%;
    height: 0;
  }

   .content-info-item .flow-img {
      width:100%;
      height:100%;
      position: absolute;
      bottom:0;
      left:0;
   }

  .content-info-item .flow-title {
    position: absolute;
    border:1px solid #ccc;
    width: 66%;
    text-align: center;
    padding:6px 0;
    bottom:0;
    left:17%;
    background-color: #fff;
  }

  .choose-ul {
    padding:20px 0;
    display: flex;
  }

  .choose-ul li {
    display: inline-block;
    width:20%;
    text-align: center;
  }

  .equipment-content {
    background-color: #fff;
    padding:10px 40px 20px 40px;
  }

  .product-slider {
    display: inline-block;
    margin:0px 1%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:23%;
    height:0;
    padding-top:15.3%;
    margin-top:20px;
    /* margin-right:12rpx; */
  }

  .product-slider .product-slider-img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

  .product-slider-title {
    margin-top:10px;
    text-align: center;
  }

  .flow-num {
    display: inline-block;
    color:#fff;
    background-color: #0094de;
    padding: 10px 8px;
    border-radius: 5px;
    margin-right:10px;
    border:1px solid #333;
  }

  .info-icon-list {
    margin:40px 0;
  }

  .info-icon-list .icon-info {
    display: block;
    width: 25%;
    text-align: center;
    float: left;
  }

  .icon-info img {
    width: 44px;
    height:44px;
  }

  .info-gray {
    background-color: #f0efef;
  }

  .info-desc {
    line-height: 16px;
  }

  .factory-contnet {
    margin-top:40px;
  }

  .factory-item {
    display: inline-block;
    margin:0px 0.5%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:24%;
    height:0;
    padding-top:16%;
    /* margin-top:20px; */
  }

  .factory-img {
     display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

  /* .flow-title {
    
  } */

  /* .choose-ul li  */

  @media (max-width: 1500px) {
    .content-info-item {
      width: 17.6%;
      padding-top: 16.3%;
    }
  }

  @media (max-width: 1360px) {
   .content-info-item .flow-title {
      width: 80%;
      left:10%;
    }
  }

  @media (max-width: 1024px) {
    .content-info-item {
      width: 23%;
      padding-top: 21.3%;
    }

    .product-slider {
      /* width:32%; */
      /* max-height:24px; */
      width:48%;
      padding-top:32%;
      /* margin-right:12rpx; */
    }

    .factory-item {
      width:49%;
      padding-top:32.7%;
    }

    .equipment-content /deep/ .carousel-indicators {
      bottom:-20px;
    }

  }

  @media (max-width: 768px) {
    .content-mian{
      padding:10px;
    }
    .content-info-item {
      width: 31.3%;
      padding-top: 29%;
    }

    .choose-ul  .size-16 {
      font-size: 12px;
    }
    .content-sub-title {
      padding:0 10px;
    }
  }

  
  @media (max-width: 460px) {
    .content-info-item .flow-title {
      width: 100%;
      left:0%;
    }

    .info-right {
      padding:4% 1%;
    }
  }
</style>